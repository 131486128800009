import { graphql } from 'gatsby';

import styled from 'styled-components';
import { Col } from 'antd';

import React, { Component } from 'react';
import { get, find } from 'lodash';
import Img from 'gatsby-image';
import breakpoint from 'styled-components-breakpoint';

import Layout from '../layout/Layout';

import { PageHero, ArticlesSection, SectionTitle, Title } from '../common/components';
import { getInternalUrl } from '../utils';

interface Props {
  data: any;
  location: any;
}

const ArticleTitle = styled(Title)`
  font-weight: bold !important;
  text-transform: uppercase;
  padding-top: 15px;
`;

const ArticleCol = styled(Col)`
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  margin-bottom: 50px;
  overflow: hidden;
`;

const ArticlePreviewImage = styled(Img)`
  width: 100% !important;
  /* height: 100% !important; */
  height: 50% !important;
`;

const ArticleContainer = styled.div`
  text-align: left;
  width: 280px !important;
  height: auto;
  ${breakpoint('sm')`
    width: 320px !important;
  `}
`;

const ShopItemTitle = styled(ArticleTitle)`
  text-align: center !important;
`;

class ShopItemCategory extends Component<Props, any> {
  getItems = (items) => {
    if (Array.isArray(items) && items.length > 0) {
      return items.map(o => get(o, 'item.document[0'));
    }

    return [];
  };

  renderitem = (item, idx) => {
    if (!item) return null;

    const imgData = get(item, 'data.featureImage');
    const title = get(item, 'data.title');

    return (
      <ArticleCol xs={24} md={12} xl={8} key={idx}>
        <ArticleContainer>
          <ArticlePreviewImage
            key={idx}
            fluid={{
              aspectRatio: 1,
              ...get(imgData, 'localFile.childImageSharp.fluid', {}),
            }}
            alt={get(imgData, 'alt', 'Nikanti image')}
          />
          {title && <ShopItemTitle level={3}>{title}</ShopItemTitle>}
        </ArticleContainer>
      </ArticleCol>
    );
  };

  render() {
    const { data } = this.props;

    const lang = get(data, 'page.lang', 'en-us');
    const title = get(data, 'page.data.title', null);
    const body = get(data, 'page.data.body');
    const heroData = find(body, { __typename: 'PrismicShopItemCategoryBodyHero' });
    const shopItemData = get(data, 'page.data.items', []);
    const backButtonText = get(data, 'page.data.backButtonText');
    const backButtonLink = get(data, 'page.data.backButtonLink');

    const uri = get(backButtonLink, 'document[0].data.url', false)
      ? getInternalUrl(get(backButtonLink, 'document[0].data.url', ''), lang)
      : get(data, 'url', '');

    return (
      <Layout data={data} location={this.props.location}>
        {heroData && <PageHero data={heroData} />}
        {title && <SectionTitle title={title} />}
        {shopItemData && (
          <ArticlesSection
            data={shopItemData}
            getItems={this.getItems}
            renderItem={this.renderitem}
            lang={get(data, 'page.lang', 'en-us')}
            backButtonText={backButtonText}
            backButtonUrl={uri}
          />
        )}
      </Layout>
    );
  }
}

export default ShopItemCategory;

export const query = graphql`
  query ShopItemCategoryQuery($slug: String!) {
    page: prismicShopItemCategory(id: { eq: $slug }) {
      ...ShopItemCategory
    }
  }
`;
